<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <v-img
                      contain
                      max-height="300px"
                      src="/explanacoes_juricas.png"
                    ></v-img>
                    <h2 class=" mb-8 font-weight-black">
                      Explanações Jurídicas
                    </h2>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      to="/app/explorar"
                    >
                      Acessar
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <v-img
                      contain
                      max-height="300px"
                      src="/cursos_e_aulas.png"
                    ></v-img>
                    <h2 class=" mb-8 font-weight-black">
                      Cursos e Aulas
                    </h2>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      to="/app/academy/cursos"
                    >
                      Acessar
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col v-if="isFuncionario" class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <v-img
                      contain
                      max-height="300px"
                      src="/cursos_e_aulas_funcionarios.png"
                    ></v-img>
                    <h2 class=" mb-8 font-weight-black">
                      Cursos e Aulas - Funcionários
                    </h2>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      to="/app/academy/cursos/funcionarios"
                    >
                      Acessar
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <v-img
                      contain
                      max-height="300px"

                      src="/tira_duvidas.png"
                    ></v-img>
                    <h2 class=" mb-8 font-weight-black">
                      Tira Dúvidas
                    </h2>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      :to="{ name: 'TiraDuvidas' }"
                    >
                      Acessar
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog width="500">
      <template v-slot:default="{ isActive }">
        <v-card title="Dialog">
          <v-card-text> </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text="Close Dialog" @click="isActive.value = false"></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Politica de Privacidade
            </v-card-title>

            <v-card-text>
              <p>
                <strong
                  >Estou ciente que não é permitida a divulgação para terceiros
                  ou uso das imagens para interesses pessoais.</strong
                >
              </p>

              <p>
                <strong
                  >Estou ciente que a utilização da plataforma é exclusiva para
                  clientes e colaboradores, sendo descontinuado o acesso para
                  quem não atender a esses requisitos.</strong
                >
              </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Aceito
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      data: [],
      dialog: true,
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
    isFuncionario: function() {
      if (!this.me) return false;

      return this.me.role.name !== "educa_ws" && this.me.role.type === "responsible";
    },
  },
};
</script>
